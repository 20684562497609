/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import { getAssetMetadata, getAssetsList } from 'cb-wallet-data/stores/Assets/api';
import {
  AssetListedQueryPayloadRequest,
  AssetMetaData,
  AssetStat,
  AssetStats,
  CoinbaseAsset,
  Resolution,
  ResourceURL,
  SortField,
  SortOrder,
} from 'cb-wallet-data/stores/Assets/types/Assets';
import {
  logPriceChartAssetMetaDataLoadFailed,
  logPriceChartAssetMetaDataLoadSucceeded,
  logPriceChartAssetStatsLoadFailed,
  logPriceChartAssetStatsLoadSucceeded,
} from 'cb-wallet-data/stores/Prices/eventing';
import isEmpty from 'lodash/isEmpty';

const QUERY_KEY = 'coinbase_asset_v3';
const staleTime = 1000 * 60 * 10; // Refresh when requested again after 10 minutes (these never change)

type AssetV3Props = {
  assetSymbol: string;
  uuid?: string;
  shouldUseV4?: boolean;
  suspense?: boolean;
};

/**
 * @deprecated in favor of useAssetV4.
 * Please use the useAsset hook with V4 enforced parameters for better coverage and accuracy:
 * V4 enforced parameters: network, blockchain, contractAddress, assetSymbol
 */
export function useAssetV3({
  assetSymbol,
  uuid = undefined,
  shouldUseV4 = false,
  suspense = false,
}: AssetV3Props) {
  const { code } = useActiveFiatCurrency();

  const assetListedQueryV2 = useMemo(() => {
    const request: AssetListedQueryPayloadRequest = {
      currency: code.rawValue,
      resolution: Resolution.RESOLUTION_DAY,
      sort_order: SortOrder.SORT_ORDER_ASCENDING,
      sort_field: SortField.SORT_FIELD_RANK,
    };

    if (uuid) {
      request.uuids = [uuid];
    } else {
      request.query = assetSymbol;
    }

    return Buffer.from(JSON.stringify(request), 'utf-8').toString('base64');
  }, [assetSymbol, code.rawValue, uuid]);

  const assetMetaDataQueryV2 = useMemo(
    () =>
      uuid
        ? Buffer.from(JSON.stringify({ uuid }), 'utf-8').toString('base64')
        : Buffer.from(JSON.stringify({ symbol: assetSymbol }), 'utf-8').toString('base64'),
    [assetSymbol, uuid],
  );

  const queryKeyAssetList = useMemo(() => [QUERY_KEY, assetListedQueryV2], [assetListedQueryV2]);
  const queryKeyAssetMetaData = useMemo(
    () => [QUERY_KEY, assetMetaDataQueryV2],
    [assetMetaDataQueryV2],
  );

  const queryClient = useQueryClient();

  const options = {
    enabled: !shouldUseV4,
    staleTime,
    suspense,
  };

  const matchV2 = useCallback(
    function matchV2(asset: AssetStat) {
      if (uuid) {
        return asset.assetUuid === uuid;
      }
      return asset.assetSymbol.toUpperCase() === assetSymbol.toUpperCase();
    },
    [assetSymbol, uuid],
  );

  const handleAssetStatsSelect = useCallback(
    (assetsListData: AssetStats | undefined) => {
      try {
        if (!assetsListData) {
          return undefined;
        }

        const assetList = (assetsListData?.assetStats ?? []).find(matchV2);
        if (assetList) {
          const latest = assetList.price;
          const marketCap = assetList.marketCap;
          const volume24h = assetList.volume24h;
          const listed = assetList.categories?.includes('listed') ?? false;
          return {
            latest,
            market_cap: marketCap,
            volume_24h: volume24h,
            listed,
          } as Pick<CoinbaseAsset, 'latest' | 'market_cap' | 'volume_24h' | 'listed'>;
        }
      } catch (err: ErrorOrAny) {
        cbReportError(err);
        queryClient.removeQueries(queryKeyAssetList);

        // ensuring contract doesn't break incase there's a backend or parsing error
        return {
          latest: '',
          market_cap: '',
          volume_24h: '',
          listed: false,
        };
      }
    },
    [matchV2, queryClient, queryKeyAssetList],
  );

  const handleAssetMetaDataSelect = useCallback(
    (assetListMetaData: AssetMetaData | undefined) => {
      try {
        if (!assetListMetaData) {
          return undefined;
        }

        const asset = assetListMetaData.asset;
        const id = asset.uuid;
        const symbol = asset.symbol;
        const color = asset.primaryColor;
        const name = asset.name;
        const description = asset.description;
        const imageUrl = asset.iconUrl;
        const resourceUrls: ResourceURL[] = [];

        if (asset?.whitePaper) {
          resourceUrls.push({
            title: '',
            link: asset.whitePaper,
            type: 'white_paper',
            icon_url: '',
          });
        }

        if (asset?.officialWebsite) {
          resourceUrls.push({
            title: '',
            link: asset.officialWebsite,
            type: 'website',
            icon_url: '',
          });
        }

        return {
          id,
          symbol,
          color,
          name,
          description,
          image_url: imageUrl,
          resource_urls: resourceUrls,
        } as Omit<CoinbaseAsset, 'latest' | 'market_cap' | 'volume_24h' | 'listed'>;
      } catch (err: ErrorOrAny) {
        cbReportError(err);
        queryClient.removeQueries(queryKeyAssetMetaData);

        // ensuring contract doesn't break incase there's a backend or parsing error
        return {
          id: '',
          symbol: '',
          color: '',
          name: '',
          description: '',
          image_url: '',
          resource_urls: [],
        };
      }
    },
    [queryClient, queryKeyAssetMetaData],
  );

  const handleAssetListSuccess = useCallback(
    function handleAssetListSuccess() {
      logPriceChartAssetStatsLoadSucceeded(assetSymbol, uuid);
    },
    [assetSymbol, uuid],
  );

  const handleAssetListError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleAssetListError(err: any) {
      logPriceChartAssetStatsLoadFailed(assetSymbol, uuid, err);
      const e = coerceError(err, 'useAsset');
      const metadata = { assetSymbol, uuid: uuid ?? '' };
      cbReportError({
        error: e,
        ...metadata,
        context: 'asset_http_error',
        severity: 'error',
        isHandled: false,
      });
    },
    [assetSymbol, uuid],
  );

  const handleAssetMetaDataSuccess = useCallback(
    function handleAssetMetaDataSuccess() {
      logPriceChartAssetMetaDataLoadSucceeded(assetSymbol, uuid);
    },
    [assetSymbol, uuid],
  );

  const handleAssetMetaDataError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleAssetMetaDataError(err: any) {
      logPriceChartAssetMetaDataLoadFailed(assetSymbol, uuid, err);
      const e = coerceError(err, 'useAsset');
      const metadata = { assetSymbol, uuid: uuid ?? '' };
      cbReportError({
        error: e,
        ...metadata,
        context: 'asset_http_error',
        severity: 'error',
        isHandled: false,
      });
    },
    [assetSymbol, uuid],
  );

  const assetListQuery = {
    queryKey: queryKeyAssetList,
    queryFn: async () => getAssetsList(assetListedQueryV2),
    select: handleAssetStatsSelect,
    onSuccess: handleAssetListSuccess,
    onError: handleAssetListError,
    ...options,
  };

  const assetMetaData = {
    queryKey: queryKeyAssetMetaData,
    queryFn: async () => getAssetMetadata(assetMetaDataQueryV2),
    select: handleAssetMetaDataSelect,
    onSuccess: handleAssetMetaDataSuccess,
    onError: handleAssetMetaDataError,
    ...options,
  };

  const { data: assetsListData, isFetched: isAssetsListFetched } = useQuery(assetListQuery);
  const { data: assetListMetaData, isFetched: isAssetListMetaDataFetched } =
    useQuery(assetMetaData);

  const asset =
    isAssetsListFetched &&
    isAssetListMetaDataFetched &&
    !(isEmpty(assetsListData) && isEmpty(assetListMetaData))
      ? ({ ...assetsListData, ...assetListMetaData } as CoinbaseAsset)
      : undefined;

  return {
    asset,
    isFetched: isAssetsListFetched && isAssetListMetaDataFetched,
  };
}
