type PerformanceEntry = {
  duration: DOMHighResTimeStamp;
  entryType: string;
  name: string;
  startTime: DOMHighResTimeStamp;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON: () => any;
};

export type PerformanceMark = PerformanceEntry & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: any;
};

export type PerformanceMeasure = PerformanceEntry & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: any;
};

export type Tags = Record<string, string>;

export type EntryType = 'mark' | 'measure' | 'resource' | 'metric' | 'react-native-mark';

export type PerformanceHandler = {
  mark: (markName: string, markOptions?: PerformanceMarkOptions) => PerformanceMark;
  clearMarks: (markName?: string) => void;
  measure: (
    measureName: string,
    startOrMeasureOptions?: string | PerformanceMeasureOptions,
    endMark?: string,
  ) => PerformanceMeasure;
  getEntriesByName: (name: string, type?: EntryType) => PerformanceEntry[];
};

export type PerfStep = 'start' | 'end';

export type PerfMarkOptions = {
  label?: string;
  tags?: Tags;
  logMetric?: boolean;
};

export type PerformanceAPI = {
  customMetrics: Map<string, number>;
  perfMark: (markName: string, step: PerfStep, options?: PerfMarkOptions) => void;
  startPerfMark: (markName: string, options?: PerfMarkOptions) => void;
  endPerfMark: (markName: string, options?: PerfMarkOptions) => void;
  clearPerformanceMarkEntries: () => void;
  clearMarkEntry: (markName: string, options?: PerfMarkOptions) => void;
};

// eslint-disable-next-line @cbhq/ts-no-enum
export enum MetricType {
  count = 'count',
  rate = 'rate',
  gauge = 'gauge',
  distribution = 'distribution',
  histogram = 'histogram',
}

export type VitalsScore = 'good' | 'needsImprovement' | 'poor';

type CoreMetricName =
  | 'appRenderComplete'
  | 'navigationTotalBlockingTime'
  | 'navigationTotalBlockingTimeExtended';

export type VitalMetricName = `${CoreMetricName}_vitals_${VitalsScore}`;

export type MetricName = CoreMetricName | `${CoreMetricName}_vitals_${VitalsScore}`;

type MetricCore = {
  value: number;
  type: MetricType;
  tags: Record<string, string>;
};

export type CustomMetric = MetricCore & {
  name: string;
  category: 'custom';
};

export type CoreMetric = MetricCore & {
  name: CoreMetricName;
  category: 'core';
};

export type VitalMetric = MetricCore & {
  name: VitalMetricName;
  category: 'vital';
};

export type LogMetricHandler = (metric: CustomMetric | CoreMetric | VitalMetric) => void;

export type ErrorHandler = (
  e: Error,
  metadata?: Record<string, string | number | boolean | string[]>,
) => void;

export type ErrorConfig = {
  notifyOnOutlier: boolean;
};

export type GetTimeSinceStartup = () => Promise<number>;

export type MetricLoggingConfig = {
  core: boolean;
  vital: boolean;
};

export type MetricsLoggingConfig = {
  [metric in CoreMetricName]: MetricLoggingConfig;
};

export type VitalConfig = {
  vitalsThresholds: [number, number];
  outlierThresholds: [number, number];
};

export type VitalsConfig = {
  [metric in CoreMetricName]: VitalConfig;
};

export type PerformanceMetricConfig = {
  threshold: number;
};

export type Navigation = {
  prevPageKey: string | null;
  prevPagePath: string | null;
  lastChangeAt: number;
  pageKey: string | null; // e.g. "Asset" screen
  pagePath: string | null; // e.g. "Asset_assetId=123" query
  currentRouteName: string | null;
};

export type PerformanceVitalsConfig = {
  errorHandler: ErrorHandler;
  errorConfig?: ErrorConfig;
  getTimeSinceStartup: GetTimeSinceStartup;
  logMetricHandler: LogMetricHandler;
  metricsLoggingConfig?: MetricsLoggingConfig;
  performanceHandler: PerformanceHandler;
  vitalsConfig?: VitalsConfig;
  performanceMetricConfig?: PerformanceMetricConfig;
  navigation: Navigation | undefined;
};
