import {
  AssetMetaData,
  AssetStats,
  CoinbaseAsset,
} from 'cb-wallet-data/stores/Assets/types/Assets';
import {
  logPriceChartAssetLoadFailed,
  logPriceChartAssetLoadSucceeded,
} from 'cb-wallet-data/stores/Prices/eventing';
import { CB_API_URL } from 'cb-wallet-env/env';
import { getJSON, postJSON } from 'cb-wallet-http/fetchJSON';
import { HTTPError } from 'cb-wallet-http/HTTPError';
import {
  V1GetAssetsDetailsRequest as StaticMetadataQueryParams,
  V1GetAssetsDetailsResponse as StaticMetadataResponse,
} from '@cbhq/instant-api-hooks-wallet-portfolio-service/types';
import {
  V1GetQuoteRequest as DynamicMetadataQueryParams,
  V1GetQuoteResponse as DynamicMetadataResponse,
} from '@cbhq/instant-api-hooks-wallet-quote-service/types';

const API_PATH = `${CB_API_URL}/v2/assets`;
const API_PATH_V3 = `${CB_API_URL}/v3/coinbase.asset_metadata_service.v2.public.AssetMetadataPublicService`;
const API_PATH_ASSETS_V3 = `${CB_API_URL}/api/v3/coinbase.asset_stats_service.AssetStatsService`;

// V4 endpoints
const STATIC_ENDPOINT = 'assets/getAssetsDetails';
const DYNAMIC_ENDPOINT = 'quote/getQuote';

export async function getAssetsSearch(assetSymbol: string, currency: string) {
  const queryArgs = {
    query: assetSymbol,
    base: currency,
    country: 'US',
    filter: 'all',
    // eslint-disable-next-line camelcase
    include_prices: 'false',
    limit: '50',
    order: 'asc',
    page: '1',
    resolution: 'day',
    sort: 'rank',
  };

  try {
    const { data } = await getJSON<{ data: CoinbaseAsset[] }>(`${API_PATH}/search`, queryArgs, {
      isThirdParty: true,
    });

    logPriceChartAssetLoadSucceeded(assetSymbol, currency, queryArgs);

    return data;
  } catch (ex) {
    logPriceChartAssetLoadFailed(assetSymbol, currency, queryArgs, ex);
  }

  return [];
}

export async function getAssetMetadata(assetId: string) {
  const queryArgs = {
    q: assetId,
  };
  try {
    const data = await getJSON<AssetMetaData>(`${API_PATH_V3}/GetAssetV2`, queryArgs, {
      isThirdParty: true,
    });

    return data;
  } catch (ex) {
    if (ex instanceof HTTPError && ex.status === 404) {
      return undefined;
    }
    throw ex;
  }
}

export async function getAssetsList(assetId: string) {
  const queryArgs = {
    q: assetId,
  };
  try {
    const data = await getJSON<AssetStats>(`${API_PATH_ASSETS_V3}/ListAssetStats`, queryArgs, {
      isThirdParty: true,
    });
    return data;
  } catch (ex) {
    if (ex instanceof HTTPError && ex.status === 404) {
      return undefined;
    }
    throw ex;
  }
}

export async function fetchStaticMetadata(params: StaticMetadataQueryParams) {
  try {
    const data = await postJSON<StaticMetadataResponse>(STATIC_ENDPOINT, params);
    return data;
  } catch (ex) {
    if (ex instanceof HTTPError && ex.status === 404) {
      return undefined;
    }
    throw ex;
  }
}

export async function fetchDynamicMetadata(params: DynamicMetadataQueryParams) {
  try {
    const data = await postJSON<DynamicMetadataResponse>(DYNAMIC_ENDPOINT, params);
    return data;
  } catch (ex) {
    if (ex instanceof HTTPError && ex.status === 404) {
      return undefined;
    }
    throw ex;
  }
}
