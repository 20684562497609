/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  logAssetDetailDynamicDataLoadFailed,
  logAssetDetailDynamicDataLoadSucceeded,
  logAssetDetailStaticDataLoadFailed,
  logAssetDetailStaticDataLoadSucceeded,
} from 'cb-wallet-analytics/assets';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { fetchDynamicMetadata, fetchStaticMetadata } from 'cb-wallet-data/stores/Assets/api';
import { CoinbaseAsset, ResourceURL } from 'cb-wallet-data/stores/Assets/types/Assets';
import { hash } from 'cb-wallet-data/utils/hash';
import isEmpty from 'lodash/isEmpty';
import {
  V1GetAssetsDetailsRequest as StaticMetadataQueryParams,
  V1GetAssetsDetailsResponse as StaticMetadataResponse,
} from '@cbhq/instant-api-hooks-wallet-portfolio-service/types';
import {
  V1GetQuoteRequest as DynamicMetadataQueryParams,
  V1GetQuoteResponse as DynamicMetadataResponse,
} from '@cbhq/instant-api-hooks-wallet-quote-service/types';

const STATIC_QUERY_KEY = 'coinbase_asset_static';
const DYNAMIC_QUERY_KEY = 'coinbase_asset_dynamic';

type AssetV4Props = {
  networkId?: string;
  contractAddress?: string;
  assetSymbol: string;
  chainId?: number;
  shouldUseV4: boolean;
  suspense?: boolean;
};

export function useAssetV4({
  networkId,
  contractAddress,
  assetSymbol,
  chainId,
  shouldUseV4,
  suspense = false,
}: AssetV4Props) {
  const queryClient = useQueryClient();

  const isBaseAsset = !contractAddress;

  const staticMetadataQueryParams: StaticMetadataQueryParams = useMemo(() => {
    return {
      networkId,
      contractAddresses: contractAddress ? [contractAddress] : [],
      nativeAssetSymbols: isBaseAsset ? [assetSymbol] : [],
      chainId: chainId ? chainId.toString() : undefined,
    };
  }, [networkId, contractAddress, isBaseAsset, assetSymbol, chainId]);

  const queryKeyStaticMetadata = useMemo(
    () => [STATIC_QUERY_KEY, hash(JSON.stringify(staticMetadataQueryParams))],
    [staticMetadataQueryParams],
  );

  const handleStaticMetadataSelect = useCallback(
    (staticMetadataResponse: StaticMetadataResponse | undefined) => {
      try {
        if (!staticMetadataResponse) {
          return undefined;
        }
        return extractStaticAssetData(staticMetadataResponse);
      } catch (err: ErrorOrAny) {
        cbReportError(err);
        queryClient.removeQueries(queryKeyStaticMetadata);

        return {
          id: '',
          symbol: '',
          color: '',
          name: '',
          description: '',
          image_url: '',
          resource_urls: [],
          listed: false,
        };
      }
    },
    [queryClient, queryKeyStaticMetadata],
  );

  const handleStaticMetadataError = useCallback(
    function handleStaticMetadataError(err: ErrorOrAny) {
      logAssetDetailStaticDataLoadFailed({
        assetSymbol,
        contractAddress,
        networkId,
        chainId,
        err,
      });
      const e = coerceError(err, 'useAssetV4');
      const metadata = { networkId, contractAddress, assetSymbol, chainId };
      cbReportError({
        error: e,
        ...metadata,
        context: 'asset_http_error',
        severity: 'error',
        isHandled: false,
      });
    },
    [assetSymbol, chainId, contractAddress, networkId],
  );

  const handleStaticMetadataSuccess = useCallback(
    function handleStaticMetadataSuccess() {
      logAssetDetailStaticDataLoadSucceeded({ assetSymbol, contractAddress, networkId, chainId });
    },
    [assetSymbol, chainId, contractAddress, networkId],
  );

  const dynamicMetadataQueryParams: DynamicMetadataQueryParams = useMemo(() => {
    return {
      networkId,
      contractAddresses: contractAddress ? [contractAddress] : [],
      nativeAssetSymbols: isBaseAsset ? [assetSymbol] : [],
      chainId: chainId ? chainId.toString() : undefined,
    };
  }, [networkId, contractAddress, isBaseAsset, assetSymbol, chainId]);

  const queryKeyDynamicMetadata = useMemo(
    () => [DYNAMIC_QUERY_KEY, hash(JSON.stringify(dynamicMetadataQueryParams))],
    [dynamicMetadataQueryParams],
  );

  const handleDynamicMetadataSelect = useCallback(
    (dynamicMetadataResponse: DynamicMetadataResponse | undefined) => {
      try {
        if (!dynamicMetadataResponse) {
          return undefined;
        }
        return extractDynamicAssetData(dynamicMetadataResponse);
      } catch (err: ErrorOrAny) {
        cbReportError(err);
        queryClient.removeQueries(queryKeyDynamicMetadata);

        return {
          latest: '',
          market_cap: '',
          volume_24h: '',
        };
      }
    },
    [queryClient, queryKeyDynamicMetadata],
  );

  const handleDynamicMetadataError = useCallback(
    function handleDynamicMetadataError(err: ErrorOrAny) {
      logAssetDetailDynamicDataLoadFailed({
        assetSymbol,
        contractAddress,
        networkId,
        chainId,
        err,
      });
      const e = coerceError(err, 'useAssetV4');
      const metadata = { networkId, contractAddress, assetSymbol, chainId };
      cbReportError({
        error: e,
        ...metadata,
        context: 'asset_http_error',
        severity: 'error',
        isHandled: false,
      });
    },
    [assetSymbol, chainId, contractAddress, networkId],
  );

  const handleDynamicMetadataSuccess = useCallback(
    function handleDynamicMetadataSuccess() {
      logAssetDetailDynamicDataLoadSucceeded({ assetSymbol, contractAddress, networkId, chainId });
    },
    [assetSymbol, chainId, contractAddress, networkId],
  );

  const staticMetadataQuery = {
    queryKey: queryKeyStaticMetadata,
    queryFn: async () => fetchStaticMetadata(staticMetadataQueryParams),
    select: handleStaticMetadataSelect,
    onError: handleStaticMetadataError,
    onSuccess: handleStaticMetadataSuccess,
    enabled: shouldUseV4,
    staleTime: 1000 * 60 * 30, // Refresh when requested again after 30 minutes
    suspense,
  };

  const dynamicMetadataQuery = {
    queryKey: queryKeyDynamicMetadata,
    queryFn: async () => fetchDynamicMetadata(dynamicMetadataQueryParams),
    select: handleDynamicMetadataSelect,
    onError: handleDynamicMetadataError,
    onSuccess: handleDynamicMetadataSuccess,
    enabled: shouldUseV4,
    staleTime: 1000 * 60 * 10, // Refresh when requested again after 10 minutes
    suspense,
  };

  const { data: staticMetadata, isFetched: isStaticMetadataFetched } =
    useQuery(staticMetadataQuery);

  const { data: dynamicMetadata, isFetched: isDynamicMetadataFetched } =
    useQuery(dynamicMetadataQuery);

  const asset =
    isStaticMetadataFetched &&
    isDynamicMetadataFetched &&
    !(isEmpty(dynamicMetadata) && isEmpty(staticMetadata))
      ? ({ ...dynamicMetadata, ...staticMetadata } as CoinbaseAsset)
      : undefined;

  return {
    asset,
    isFetched: isDynamicMetadataFetched && isStaticMetadataFetched,
  };
}

function extractStaticAssetData(staticMetadataResponse: StaticMetadataResponse) {
  const asset = staticMetadataResponse.result?.assets?.[0];
  if (asset) {
    const {
      coinbaseUuid: id,
      symbol,
      name,
      color,
      description,
      imageUrl,
      coinbaseListed: listed,
    } = asset;

    const resourceUrls: ResourceURL[] = [];

    if (asset?.whitePaperUrl) {
      resourceUrls.push({
        title: '',
        link: asset.whitePaperUrl,
        type: 'white_paper',
        icon_url: '',
      });
    }

    if (asset?.websiteUrl) {
      resourceUrls.push({
        title: '',
        link: asset.websiteUrl,
        type: 'website',
        icon_url: '',
      });
    }

    return {
      id,
      symbol,
      color,
      name,
      description,
      image_url: imageUrl,
      resource_urls: resourceUrls,
      listed,
    } as Omit<CoinbaseAsset, 'latest' | 'market_cap' | 'volume_24h'>;
  }
}

function extractDynamicAssetData(dynamicMetadataResponse: DynamicMetadataResponse) {
  const asset = dynamicMetadataResponse.results?.[0];
  if (asset) {
    const latest = asset.price ? asset.price.toString() : '';
    const marketCap = asset.marketCap ? asset.marketCap.toString() : '';
    const volume24h = asset.volume24h ? asset.volume24h.toString() : '';

    return {
      latest,
      market_cap: marketCap,
      volume_24h: volume24h,
    } as Pick<CoinbaseAsset, 'latest' | 'market_cap' | 'volume_24h'>;
  }
}
