import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getNetworkId } from 'cb-wallet-data/stores/Networks/utils/getNetworkId';

import { useAssetV3 } from './useAssetV3';
import { useAssetV4 } from './useAssetV4';

type UseAssetProps = {
  assetSymbol: string;
  network?: Network;
  blockchain?: Blockchain;
  contractAddress?: string;
  uuid?: string | undefined;
  suspense?: boolean;
};

/*
 * Please use this hook with V4 enforced parameters for better coverage and accuracy:
 * V4 enforced parameters: network, blockchain, contractAddress, assetSymbol
 */
export function useAsset({
  network,
  blockchain,
  contractAddress,
  assetSymbol,
  uuid = undefined,
  suspense = false,
}: UseAssetProps) {
  const isAssetV4Enabled = useIsFeatureEnabled('asset_metadata_v4_migration');
  const chainId = network?.asChain()?.chainId;
  const networkId = network?.asChain()?.isCustomNetwork
    ? undefined
    : getNetworkId({ network, blockchain });

  const isV4QueryParamsAvailable = Boolean(
    (networkId && contractAddress) ||
      (networkId && assetSymbol) ||
      (chainId && contractAddress) ||
      (chainId && assetSymbol),
  );
  const shouldUseV4 = isV4QueryParamsAvailable && isAssetV4Enabled;

  const { asset: assetV3Data, isFetched: isAssetV3DataFetched } = useAssetV3({
    assetSymbol,
    uuid,
    shouldUseV4,
    suspense,
  });

  const { asset: assetV4Data, isFetched: isAssetV4DataFetched } = useAssetV4({
    networkId,
    contractAddress,
    assetSymbol,
    chainId,
    shouldUseV4,
    suspense,
  });

  return {
    asset: shouldUseV4 ? assetV4Data : assetV3Data,
    isFetched: shouldUseV4 ? isAssetV4DataFetched : isAssetV3DataFetched,
  };
}
