import { logDataEvent } from 'cb-wallet-analytics/utils/log';
import { logMetric } from '@cbhq/client-analytics';
import { MetricType } from '@cbhq/core-vitals/types';

export function logPriceChartAssetLoadSucceeded(
  assetSymbol: string,
  currency: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryArgs: any,
) {
  const EVENT_NAME = 'price_chart.asset.load_succeeded';

  logDataEvent(EVENT_NAME, {
    loggingId: 'e7348dd7-3d12-448a-93a7-feeab64dc35b',
    tokenSymbol: assetSymbol,
    currencyCode: currency,
    request: queryArgs,
  });
}

export function logPriceChartAssetStatsLoadSucceeded(
  assetSymbol: string,
  uuid: string | undefined,
) {
  const EVENT_NAME = 'price_chart.asset_stats_v3.load_succeeded';

  logDataEvent(EVENT_NAME, {
    loggingId: '6074c9dd-abb6-4ddc-a454-9c58bcf0e6c5',
    tokenSymbol: assetSymbol,
    uuid,
  });
}

export function logPriceChartAssetMetaDataLoadSucceeded(
  assetSymbol: string,
  uuid: string | undefined,
) {
  const EVENT_NAME = 'price_chart.asset_metadata_v3.load_succeeded';

  logDataEvent(EVENT_NAME, {
    loggingId: '19d9c71a-83b8-443a-a1b8-7aa46132cd32',
    tokenSymbol: assetSymbol,
    uuid,
  });
}

export function logPriceChartAssetLoadFailed(
  assetSymbol: string,
  currency: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryArgs: any,
  error: ErrorOrAny,
) {
  const EVENT_NAME = 'price_chart.asset.load_failed';

  logDataEvent(EVENT_NAME, {
    loggingId: '251bfaa4-4a7a-45c0-a5d3-85356e9ef793',
    tokenSymbol: assetSymbol,
    currencyCode: currency,
    request: queryArgs,
    error: String(error),
  });
}

export function logPriceChartAssetStatsLoadFailed(
  assetSymbol: string,
  uuid: string | undefined,
  error: ErrorOrAny,
) {
  const EVENT_NAME = 'price_chart.asset_stats_v3.load_failed';

  logDataEvent(EVENT_NAME, {
    loggingId: '2f4f42b8-c663-4e20-9192-5e1590a4be7a',
    tokenSymbol: assetSymbol,
    uuid,
    error: String(error),
  });
}

export function logPriceChartAssetMetaDataLoadFailed(
  assetSymbol: string,
  uuid: string | undefined,
  error: ErrorOrAny,
) {
  const EVENT_NAME = 'price_chart.asset_metadata_v3.load_failed';

  logDataEvent(EVENT_NAME, {
    loggingId: '9f213d12-3dff-4b34-8404-1ea214674b88',
    tokenSymbol: assetSymbol,
    uuid,
    error: String(error),
  });
}

export function logPriceChartPricesLoadSucceeded(assetId: string, currency: string) {
  const EVENT_NAME = 'price_chart.prices.load_succeeded';

  logMetric({
    metricName: EVENT_NAME,
    metricType: MetricType.count,
    value: 1,
    tags: {
      baseAssetSymbol: assetId,
      currencyCode: currency,
    },
  });
}

export function logPriceChartPricesLoadFailed(
  assetId: string,
  currency: string,
  error: ErrorOrAny,
) {
  const EVENT_NAME = 'price_chart.prices.load_failed';

  logMetric({
    metricName: EVENT_NAME,
    metricType: MetricType.count,
    value: 1,
    tags: {
      baseAssetSymbol: assetId,
      currencyCode: currency,
      error: String(error),
    },
  });
}
