export type ResourceURL = {
  icon_url: string;
  link: string;
  title: string;
  type: string;
};

export type CoinbaseAsset = {
  id: string;
  symbol: string;
  color: string; // hex color string
  name: string;
  description: string;
  latest: string; // The latest price, in the requested currency
  image_url: string;
  market_cap: string;
  volume_24h: string;
  resource_urls: ResourceURL[];
  listed: boolean;
};

export enum Currency {
  Usd = 'USD',
}

export type PercentChanges = {
  hour: string;
  day: string;
  week: string;
  month: string;
  year: string;
  all: string;
};

export type PageDescription = {
  size: number;
  nextCursor: string;
  timestamp: string;
  totalItems: number;
};

// v3 getAssetsList response
export type AssetStats = {
  pageDescription: PageDescription;
  assetStats: AssetStat[];
  paginationExpired: boolean;
};
export type AssetStat = {
  uuid: string;
  assetSymbol: string;
  assetUuid: string;
  assetName: string;
  assetSlug: string;
  currency: Currency;
  price: string;
  allTimeHigh: string;
  circulatingSupply: string;
  dateAdded: string;
  totalSupply: string;
  marketCap: string;
  dominance: string;
  volume24h: string;
  percentChanges: PercentChanges;
  volumePercentChange24h: string;
  timestamp: string;
  fetchedAt: string;
  cmcRank: number;
  categories?: string[];
  cmcId: number;
  unitPriceScale: number;
  maxSupply?: string;
  maxSupplySet?: boolean;
  fullyDilutedMarketCap?: string;
  fullyDilutedMarketCapSet?: boolean;
};

// v3 getAssetMetadata response
export type AssetMetaData = {
  asset: Asset;
};
export type Asset = {
  uuid: string;
  slug: string;
  symbol: string;
  name: string;
  createdAt: string;
  launchedAt: string;
  description: string;
  iconUrl: string;
  whitePaper: string;
  officialWebsite: string;
  primaryColor: string;
  categories: string[];
  networks: Network[];
  displaySymbol: string;
  legacyDecimal: number;
};

export type Network = {
  name: string;
  currency?: string;
  type: string;
  decimal?: number;
  defaultNetwork?: boolean;
  displayName: string;
  chainId?: string;
  uriScheme?: string;
  resourceName?: string;
  contractAddress?: string;
};

export enum SortField {
  SORT_FIELD_UNSPECIFIED = 'SORT_FIELD_UNSPECIFIED',
  SORT_FIELD_MARKET_CAP = 'SORT_FIELD_MARKET_CAP',
  SORT_FIELD_ASSET_NAME = 'SORT_FIELD_ASSET_NAME',
  SORT_FIELD_PERCENT_CHANGE = 'SORT_FIELD_PERCENT_CHANGE',
  SORT_FIELD_RANK = 'SORT_FIELD_RANK',
  SORT_FIELD_PRICE = 'SORT_FIELD_PRICE',
  SORT_FIELD_VOLUME = 'SORT_FIELD_VOLUME',
}

// Sort Order to specify how we sort for ListAssetStatsRequest
export enum SortOrder {
  SORT_ORDER_UNSPECIFIED = 'SORT_ORDER_UNSPECIFIED',
  SORT_ORDER_ASCENDING = 'SORT_ORDER_ASCENDING',
  SORT_ORDER_DESCENDING = 'SORT_ORDER_DESCENDING',
}

// Time resolution used in different queries.
export enum Resolution {
  RESOLUTION_UNSPECIFIED = 'RESOLUTION_UNSPECIFIED',
  RESOLUTION_HOUR = 'RESOLUTION_HOUR',
  RESOLUTION_DAY = 'RESOLUTION_DAY',
  RESOLUTION_WEEK = 'RESOLUTION_WEEK',
  RESOLUTION_MONTH = 'RESOLUTION_MONTH',
  RESOLUTION_YEAR = 'RESOLUTION_YEAR',
  RESOLUTION_ALL = 'RESOLUTION_ALL',
}

export type AssetListedQueryPayloadRequest = {
  currency: string;
  resolution: Resolution;
  sort_order: SortOrder;
  sort_field: SortField;
  uuids?: string[];
  query?: string;
};
